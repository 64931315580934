import Image from 'next/image';
import styled from 'styled-components';

type TestimonialProps = {
  name: string;
  text: string;
  bio: string;
  image: string;
}

const Quote = styled.p`
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  position: relative;
  &:before, &:after{
    content: url('/images/marketing/testimonials/quotes.svg');
    position: absolute;
  }
  &:before{
    top: 0;
    left: 0;
  }
  &:after{
    bottom: 0;
    right: 0;
    transform: rotateY(180deg) rotateX(180deg);
  }
`
const TestimonialDiv = styled.div`
  background-color: var(--bg-color);
  :nth-child(2n){
    --bg-color: #F6DAF8;
  }
  :nth-child(3n){
    --bg-color: #E3EFFB;
  }
    :nth-child(4n), :nth-child(1){
    --bg-color: #FBF5E3;
  }
`

const Slider = styled.div`
  display: flex;
  gap: 2rem;
  flex-wrap: none;
  overflow-x: auto;
  scroll-snap-type: x mandatory;
  > div{
    scroll-snap-align: center;
  }
`

const Testimonial = ({ name, text, bio, image }: TestimonialProps) => {
  return <TestimonialDiv className="py-12 px-6 md:px-10 bg-opacity-25 flex items-center flex-col text-center min-w-[80vw] md:min-w-[670px] max-w-[670px] rounded-lg">
    <div className="rounded-full overflow-hidden">
      <Image width={120} height={120} src={`/images/marketing/testimonials/${image}`} alt={`picture of ${name}`} />
    </div>
    <Quote className="text-xl md:text-2xl leading-relaxed">
      {text}
    </Quote>
    <div className="font-bold mb-4">{name}</div>
    <div className="">{bio}</div>
  </TestimonialDiv>
}

export default function Testimonials({ quotes, className }: { quotes: TestimonialProps[], className: string }){
  return <Slider className={["items-center", className].join(' ')}>
    {quotes.map((t) => <Testimonial {...t} key={t.name} />)}
  </Slider>
}