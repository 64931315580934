import React, { ReactNode } from "react";
import cs from "classnames";
import styled, { css } from "styled-components";
import Image from 'next/image';

const successImg = require('/public/images/checkmark.svg').default
const loadingImg = require('/public/images/spinner.svg').default

type Variants = "outline" | "primary" | "white"
type Sizes = "xl" | "l" | "sm" | "auto" | "full" | "xs"

export type ButtonProps = {
  children: ReactNode;
  className?: string;
  variant?: Variants;
  disabled?: boolean;
  size?: Sizes;
  loading?: boolean;
  success?: boolean;
} & React.ButtonHTMLAttributes<HTMLButtonElement>;

type VisualProps = { size: Sizes, variant: Variants, disabled: boolean | undefined, className: string | undefined }

const buttonClasses = ({ size, variant, disabled, className }: VisualProps) => cs(
  "hover:opacity-90 rounded px-4 min-w font-medium text-normal",
  {
    // tailwind seems to not produce the min-w-48 and min-w-24 classes...
    "min-w-[18rem]": size === "xl",
    "min-w-[12rem]": size === "l",
    "min-w-[6rem]": size === "sm",
    "min-w-auto": size === "auto" || size === 'xs',
    "py-3": size !== 'sm',
    "py-2": size === 'sm',
    "py-1": size === 'xs',
    "px-4": size !== 'xs',
    "px-2": size === 'xs',
  },
  {
    "w-full": size === "full",
  },
  {
    "border-aux bg-background text-aux":
      variant === "outline" && disabled,
  },
  className
)

export function Button({
  children,
  variant = "primary",
  className,
  size = "l",
  loading,
  success,
  ...props
}: ButtonProps) {
  const { disabled } = props
  return (
    <StyledButton
      type="button"
      variant={variant}
      className={buttonClasses({ size, variant, disabled, className })}
      {...props}
    >
      {(() => {
        if(success) return <Image alt="success" src={successImg} width={20} height={20} />
        if(loading) return <div className="animate-spin"><Image alt="loading" src={loadingImg} width={20} height={20} /></div>
        return children
      })()}

    </StyledButton>
  );
}


const styles = css`
  ${({
    // @ts-ignore
    variant,
  }) => {
    switch (variant) {
      case "outline":
        return css`
          --color: #5c00c3;
          --bg-color: transparent;
          --border-color: #5c00c3;
          --bg-hover: #f7f2fc;
          --bg-focus: #f7f2fc;
          --bg-active: #efe6f9;
        `;
      case "primary": {
        return css`
          --color: #fff;
          --bg-color: #5c00c3;
          --border-color: transparent;
          --bg-hover: #6d1ac9;
          --bg-focus: #6d1ac9;
          --bg-active: #7d33cf;
        `;
      }
      case "white": {
        return css`
          --border-color: transparent;
          --color: #5c00c3;
          --bg-color: #fff;
          --bg-hover: #f7f2fc;
          --bg-focus: #f7f2fc;
          --bg-active: #7d33cf;
          --color: #5c00c3;
        `;
      }
    }
  }}
  color: var(--color);
  background-color: var(--bg-color);
  border: 1px solid var(--border-color);
  &:hover {
    background: var(--bg-hover);
  }
  &:focus {
    background: var(--bg-focus);
    outline: none;
  }
  &:active {
    background: var(--bg-active);
  }
  &:disabled {
    background: #f2f2f2;
    color: #bcc0c6;
    cursor: not-allowed;
  }
`

const ButtonA = styled.a<{
  variant: "outline" | "primary" | "white";
}>`
  ${styles}
  display: inline-block;
  align-items: center;
`;

export type ButtonLinkProps = {
  children: ReactNode;
  className?: string;
  variant?: Variants;
  disabled?: boolean;
  size?: Sizes;
} & React.AnchorHTMLAttributes<HTMLAnchorElement>;

//@ts-ignore
export const ButtonLink = (props: ButtonLinkProps) => <ButtonA {...props} className={buttonClasses(props as VisualProps)}/>

const StyledButton = styled.button<{
  variant: "outline" | "primary" | "white";
}>`${styles}`;
