import Head from 'next/head';

const url = process.env.NEXT_PUBLIC_VERCEL_URL

export default function OGTags({title = 'Reveel', description = "Get your music admin done right!", image }: { title?: string, description?: string, image?: string}) {
  return <Head>
    <title>{title}</title>
    <meta property='og:url' content={"https://royalties.reveel.id"} />
    <meta property='og:type' content={"article"} />
    <meta property='og:title' content={title} />
    <meta property='og:description' content={description} />
    <meta property='og:image' content={`https://${url}${image}`} />
  </Head>
}