import router, { useRouter } from 'next/dist/client/router';
import OGTags from '../shared/ui/OGTags';

import { serverSideTranslations } from "next-i18next/serverSideTranslations";
import { i18n } from "next-i18next";
import { useTranslation } from 'react-i18next';

import homeJSON from "../public/locales/en/home.json";
import Image from 'next/image';
import styled from 'styled-components';
import { Button } from "@shared/ui/Button";
import Testimonials from '../features/home/Testimonials/index';
import Header from '../shared/ui/Header';
import Footer from '../shared/ui/Footer';
import { useEffect } from 'react';

const Hero = () => {
  const { t } = useTranslation("home")

  return <div className="md:text-center text-xl md:text-2xl pt-20 pb-16 sm:pt-[12.5rem] sm:pb-40 max-w-[70ch] mx-auto">
    <h1 className="text-brand font-bold text-3xl md:text-6xl mb-10 leading-relaxed   md:leading-[80px]">{t("hero.title")}</h1>
    <h2 className="text-xl md:text-2xl mb-2 md:leading-relaxed">{t("hero.subtitle")}</h2>
    <h2 className="text-xl md:text-2xl md:leading-relaxed ">{t("hero.subsubtitle")}</h2>
  </div>
}

type SectionProps = {
  title: string;
  subtitle: string;
  bulletPoints: string[];
  image: string,
  cta: {
    url: string;
    text: string;
  }
}

const Li = styled.li`
  list-style-image: url('/images/marketing/check.svg');
  margin-left: 1.25em;
  margin-bottom: 1rem;
`

const SectionDiv = styled.section`
  :nth-child(odd) > div:first-child{
   order: 1;
  }
`

const Section = ({ title, subtitle, bulletPoints, image, cta: { url, text: buttonText } }: SectionProps) => {
  const router = useRouter()
  return <SectionDiv className="grid py-12 md:py-[5.5rem] md:grid-cols-2 gap-5 md:gap-12 text-xl md:text-2xl flex-col-reverse">
    <div className="order-1 md:order-none">
      <h3 className="mb-6 md:mb-8 text-2xl md:text-5xl font-bold">{title}</h3>
      <p className="mb-4 md:mb-6 md:leading-[40px]">{subtitle}</p>
      <ul className="mb-16">
        {bulletPoints.map((point) => <Li key={point}>
          {point}
        </Li>)}
      </ul>
      <Button onClick={() => router.push(url)} size="xl" className="mr-6 py-4 text-lg">
        {buttonText}
      </Button>
    </div>
    <div>
      <Image src={`/images/marketing/${image}`} width={670} height={472} alt="audit-screenshot"/>
    </div>
  </SectionDiv>
}

export default function Home(){
  const { sections, testimonials } = homeJSON

  const router = useRouter()
  // this page shouldn't exist at the moment,
  // and vercel's redirection doesn't work,
  // so we hack this horrible thing...
  useEffect(() => {
    router.push('/locate-your-royalties')
  }, [router])
  return null

  return <div className="px-4 pb-6">
    <OGTags />
    <Header fixed />
    <Hero />
    <section className="divide-y divide-y-1 md:divide-y-0 mb-12 max-w-[1400px] mx-auto">
      {sections.map((s) => <Section {...s} key={s.title} />)}
    </section>
    <section className="pt-[5.5rem]">
      <h3 className="max-w-[1400px] mx-auto mb-6 md:mb-16 text-2xl md:text-5xl font-bold text-center">{testimonials.title}</h3>
      <Testimonials className="-mx-4 px-4 md:px-4" quotes={testimonials.quotes} />
    </section>
    <Footer />
  </div>;
}


export async function getStaticProps({ locale }: { locale: any }) {
  if (process.env.NODE_ENV === "development") {
    await i18n?.reloadResources();
  }

  return {
    props: {
      ...(await serverSideTranslations(locale, ["common", "home"])),
      // Will be passed to the page component as props
    },
  };
}
